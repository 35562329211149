export const msalConfig = {
    auth: {
        clientId: "51e486d7-c5a6-4dbb-98d1-c447a578caa0",
        authority: "https://login.microsoftonline.com/3ec51fe7-5201-43bd-910e-db69cc9e9d65/v2.0",
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false, // Set to true for IE11 or Edge
    },
};

export const azureScope = ["https://cloudastera.com/access_as_user offline_access"]