import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "Config/routes";
import PrivateRoute from "Components/Custom/private_route/PrivateRoute.index";
import FullPageLoader from "Components/Basic/full_page_loader/FullPageLoader.index";
import PublicRoute from "Components/Custom/public_route/PublicRoute.index";

import { useAppDispatch } from "Store/hooks";

import "./App.css";
import "Styles/index.css";
import { Modal } from "antd";
import { setErrorState } from "Components/Basic/ErrorBoundary/redux/slice";
import { setLoadingState } from "Components/Basic/full_page_loader/redux/slice";
import { useMsal } from "@azure/msal-react";
import {
  getAPIUrl,
  setupAxiosInterceptors,
} from "Utilities/ApiHelpers/ApiHelpers.index";
import { ServerInfoPopup } from "Components/Custom/ServerInfoPopup";
import { multitenantRoutes } from "Constants/api";
import axios from "axios";

const Startup = () => {
  const dispatch = useAppDispatch();
  const { instance } = useMsal();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [repoBuildLoader, setRepoBuildLoader] = useState(false);
  const [serverInfoPopupOpen, setServerInfoPopupOpen] = useState(false);

  const checkMultiTenantDbExistence = async () => {
    const url = `${getAPIUrl()}${multitenantRoutes.checkMultiTenantDb}`;
    return (await axios.get(url)).data;
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const buildMultiTenantDbAndDefaultTenant = async () => {
    try {
      setRepoBuildLoader(true);
      const url = `${getAPIUrl()}${
        multitenantRoutes.setupMultiTenantDbAndDefaultTenant
      }`;
      await axios.get(url);
    } catch (err: any) {
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        err ||
        "Something went wrong";
      dispatch(
        setErrorState({
          message: "Error",
          description: errorMessage,
          placement: "topRight",
          type: "error",
        })
      );
    } finally {
      setRepoBuildLoader(false);
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const checkMultiTenantDbAndPromptUser = async () => {
    try {
      dispatch(setLoadingState(true));
      const isDbExists = await checkMultiTenantDbExistence();

      if (!isDbExists) {
        showModal();
      }
    } catch (err: any) {
      debugger;
      dispatch(
        setErrorState({
          message: "Error",
          description: err.message || err || "Something went wrong",
          placement: "topRight",
          type: "error",
        })
      );
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  useEffect(() => {
    const serverUri = localStorage.getItem("serverUri");
    if (process.env.NODE_ENV === "development") {
      if (!serverUri) setServerInfoPopupOpen(true);
      else checkMultiTenantDbAndPromptUser();
    }
  }, []);

  useEffect(() => {
    setupAxiosInterceptors(navigate);
  }, [navigate]);

  return (
    <>
      <Modal
        title="Multi Tenant Database Build"
        open={isModalOpen}
        onOk={buildMultiTenantDbAndDefaultTenant}
        onCancel={handleCancel}
        okText="Confirm"
        closable={false}
        maskClosable={false}
        confirmLoading={repoBuildLoader}
      >
        <p>
          It seems that the multi-tenant database is not setup. Please click
          confirm to setup the multi-tenant database and the default tenant.
        </p>
      </Modal>
      {serverInfoPopupOpen && (
        <ServerInfoPopup
          open={serverInfoPopupOpen}
          setOpen={setServerInfoPopupOpen}
          checkDbExistence={checkMultiTenantDbAndPromptUser}
        />
      )}
      <Routes>
        {PRIVATE_ROUTES.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <PrivateRoute>
                {route?.layout !== undefined ? (
                  <FullPageLoader>
                    <route.layout>
                      {" "}
                      <route.element />
                    </route.layout>
                  </FullPageLoader>
                ) : (
                  <FullPageLoader>
                    <route.element />
                  </FullPageLoader>
                )}
              </PrivateRoute>
            }
          />
        ))}
        {PUBLIC_ROUTES.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <PublicRoute>
                <FullPageLoader>
                  <route.element />
                </FullPageLoader>
              </PublicRoute>
            }
          />
        ))}
      </Routes>
    </>
  );
};

export default Startup;
