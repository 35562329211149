import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "Store";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "Components/Basic/ErrorBoundary/ErrorBoundary.index";
import { ConfigProvider } from "antd";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./Config/authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

const initializeApp = async () => {
  await msalInstance.initialize();

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <ConfigProvider theme={{ token: { fontFamily: "Roboto" } }}>
          <Provider store={store}>
            <BrowserRouter>
              <ErrorBoundary />
              <App />
            </BrowserRouter>
          </Provider>
        </ConfigProvider>
      </MsalProvider>
    </React.StrictMode>
  );
};

initializeApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
