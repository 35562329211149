import { Input, Modal } from "antd";
import { useState } from "react";

export const ServerInfoPopup = ({
  open,
  setOpen,
  checkDbExistence,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  checkDbExistence: () => Promise<void>;
}) => {
  const [serverUri, setServerUri] = useState("");

  const saveServerUri = async () => {
    localStorage.setItem("serverUri", serverUri);
    setOpen(false);
    await checkDbExistence();
  };

  return (
    <Modal
      onOk={saveServerUri}
      open={open}
      onCancel={() => setOpen(false)}
      title="Input your Tenant Sever URI"
    >
      <div>
        <p>
          This is an example of the URI: https://localhost:9264. <br />{" "}
          Please be careful to add the complete address.
        </p>
        <Input
          value={serverUri}
          onChange={(e) => setServerUri(e.target.value)}
          placeholder="https://localhost:9264"
        />
      </div>
    </Modal>
  );
};
